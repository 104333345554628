/* Container principale du résumé */
.resume-container {
  margin-bottom: 2rem;
  line-height: 1.6;
  padding: 0 1rem;
}

/* Disposition du résumé */
.myself {
  display: flex;
  align-items: flex-start;
  gap: 4rem;
  flex-wrap: wrap;
}

/* Styles des titres */
.resume-container h1 {
  font-size: 3rem;
  font-weight: 600;
  color: #333;
  font-style: italic;
  margin-bottom: 0.5rem;
}

.resume-container h2 {
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #555;
}

/* Style du résumé */
.myself .summary {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
}

/* Styles de l'image */
.image-content-1 {
  border-radius: 30px;
  background-color: #f0f0f0;
  transform: rotate(5deg);
  overflow: hidden;
}

.myself img {
  
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: cover;
  border-radius: 30px;
  transform: rotate(-5deg);
}

/* Styles des médias sociaux */
.social-media {
  margin-top: 1rem;
}

.social-media ul {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.social-media ul li img {
  border-radius: 0.3rem;
  border: 2px solid #ffcc00;
  transition: transform 0.5s;
}

.social-media ul li img:hover {
  transform: scale(1.1);
}

.social-media ul li button {
  background-color: #000000;
  color: #fff;
  padding: .0rem 2rem;
  border-radius: 0.3rem;
  border: 2px solid #ffcc00;
  transition: transform 0.5s;
  font-weight: 600;
  cursor: pointer;
  height: 39px;
}

.social-media ul li button:hover {
  transform: scale(1.1);

  background-color: rgb(32, 32, 32);
}

/* Media Queries pour la responsivité */
@media only screen and (max-width: 900px) {
  .myself {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .myself img {
      width: 100%;
      max-width: 300px;
  }
}

@media only screen and (max-width: 580px) {
  .resume-container h1 {
      font-size: 2.5rem;
      margin: 1rem 0;
      font-weight: 600;
      color: #080808;
      font-style: normal;
      text-transform: uppercase;
  }

  .myself {
      flex-direction: column-reverse;
      gap: 2rem;
  }

  .image-content-1 {
      margin-bottom: 2rem;
      transform: rotate(0);
  }

  .myself img {
      transform: rotate(0);
  }

  .resume-container h2 {
      font-size: 2rem;
  }
}
