.wrapper-0{
    margin: 0 auto;
    max-width: 65rem;
}
.wrapper-1{
    margin: 0 auto;
    margin-top: 7rem;
    max-width: 65rem;
    padding: 1rem 20px;
}

@media only screen and (max-width: 830px) {

    .wrapper-1{
        margin: 0 auto;
        margin-top: 5rem;
        max-width: 60rem;
        padding: 1rem 20px;
    }
  }