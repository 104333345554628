.form-container {
    max-width: 100%;
    margin: 3rem auto;
    padding: 2rem 10%;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.form-title {
    font-size: 2.5rem;
    text-align: left;
    margin-bottom: 1.5rem;
    color: #333;
    font-weight: bold;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #555;
}

input,
textarea {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-family: "Laila", serif;
    font-weight: 100;
    font-style: normal;
}

input:focus,
textarea:focus {
    border-color: #0073e6;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 115, 230, 0.5);
}

textarea {
    resize: none;
    height: 100px;
}

.submit-button {
    padding: 0.8rem;
    font-size: 1rem;
    width: 10rem;
    background-color: #0073e6;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #005bb5;
}

.error-message {
    font-size: 0.875rem;
    color: #e63946;
    margin-top: 0.5rem;
}

.input-error {
    border-color: #e63946;
}

.success-message {
    margin-bottom: 1rem;
    padding: 0.8rem;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 4px;
    text-align: center;
}