/* Container for project details */
.project-details-container {
    text-align: center;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    
}

/* Project title */
.project-details-container h2 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 1.5rem;
    color: #000;
    letter-spacing: 1px;
}

/* Project description */
.project-details-container p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    color: #575757;
}

/* Technologies used section */
.technologies-section {
    margin: 2rem 0;
    font-size: 1.2em;
    color: #fff;
    padding: 1rem;
    border-radius: 10px;
}

/* Links section */
.links-section {
    margin-top: 2rem;
}

.links-section h3 {
    font-size: 1.5em;
    margin-bottom: 1rem;
    color: #000;
}

.links-section ul {
    list-style-type: none;
    padding: 0;
}

.links-section li {
    display: inline;
    margin-right: 1rem;
}

.links-section a {
    text-decoration: none;
    font-size: 1.1em;
    color: #000;
    transition: color 0.3s ease;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    background-color: #ffcc00;
}

.links-section a:hover {
    color: #fff;
    background-color: #000;
}

/* Responsive styles */
@media (max-width: 768px) {
    .project-details-container {
        padding: 1rem;
    }

    .project-details-container h2 {
        font-size: 2em;
    }

    .project-details-container p {
        font-size: 1em;
    }

    .technologies-section {
        font-size: 1em;
        padding: 0.5rem;
    }

    .links-section h3 {
        font-size: 1.3em;
    }

    .links-section a {
        font-size: 1em;
    }
}

@media (max-width: 480px) {
    .project-details-container h2 {
        font-size: 1.8em;
    }

    .project-details-container p {
        font-size: 0.9em;
    }

    .technologies-section {
        font-size: 0.9em;
        padding: 0.5rem;
    }

    .links-section h3 {
        font-size: 1.2em;
    }

    .links-section a {
        font-size: 0.9em;
    }
}
