.footer-container {
    border-top: 1px solid #f1f1f1;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
}

.footer-container nav ul {
    display: flex;
    align-items: center;
    gap: 1rem;

}

.footer-container a {
    text-decoration: none;
    color: black;
    font-weight: 450;
}

.footer-container a:hover {
    color: #ffcc00;
}

.footer-container p {
    color: #a1a1a1;
    font-style: oblique;
}

@media only screen and (max-width: 1000px) {
    .footer-container {
        border-top: 1px solid #f1f1f1;
        padding: 20px 15px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .footer-container a {
        text-decoration: none;
        
        color: black;
    }
}