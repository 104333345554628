/* Container for project files */
.project-files-container {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem 0;
    position: relative;
    border-radius: 15px;
    
}

/* Image and video content */
.image-content, .video-content {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-content:hover, .video-content:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

/* File changer buttons */
.file-changer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: 1rem 0;
}

.btn-change-files {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #a7a7a7;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn-change-files:hover {
    background-color: #000;
    transform: scale(1.2);
}

.project-files-container .select {
    background-color: #000;
    transform: scale(1.3);
}
@media (max-width: 768px) {
    .image-content, .video-content {
        width: 100%;
        object-fit: contain;
        border-radius: 20px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }  
}