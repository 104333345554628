.about-container {
    background-color: #fff;
    color: #333;
    overflow: hidden;
    padding: 20px;
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.about-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #222;
    font-weight: 700;
    text-align: center;
    animation: slideInFromLeft 1s ease-out;
}

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.about-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    animation: slideInFromRight 1s ease-out;
}

@keyframes slideInFromRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.about-image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    animation: zoomIn 1s ease-out;
}

@keyframes zoomIn {
    from {
        transform: scale(0.8);
    }
    to {
        transform: scale(1);
    }
}

.about-photo {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 5%;
    object-fit: cover;
    transform: rotate(2deg);
}

.about-text {
    flex: 2;
}

.about-paragraph {
    font-size: 1rem;
    line-height: 1.6;
    color: #444;
}

.about-link {
    color: #0073e6;
    text-decoration: none;
    font-weight: 500;
}

.about-link:hover {
    text-decoration: underline;
}

.about-paragraph ul {
    list-style: none;
    padding: 0;
    margin: 20px 0;
}

.about-paragraph ul li {
    margin: 10px 0;
}

.about-paragraph ul li a {
    text-decoration: none;
    color: #0073e6;
    transition: color 0.3s;
}

.about-paragraph ul li a:hover {
    color: #005bb5;
}

@media only screen and (max-width: 768px) {
    .about-content {
        flex-direction: column;
        align-items: center;
    }

    .about-image-wrapper {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .about-photo {
        max-width: 100%;
        transform: none;
    }

    .about-title {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .about-paragraph {
        font-size: 0.9rem;
    }

    .about-title {
        font-size: 1.8rem;
    }
}
