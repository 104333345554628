.NoFound-content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    font-family: 'Arial', sans-serif;
    color: #333;
}

.NoFound-container {
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.NoFound-container h1 {
    font-size: 96px;
    margin: 0;
    color: #ff6b6b;
}

.NoFound-container p {
    font-size: 24px;
    margin: 10px 0;
}

.NoFound-container strong {
    color: #ff6b6b;
}

.home-link {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff6b6b;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #ff4757;
}
